var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { size: "lg", centered: "", "hide-footer": "", scrollable: "" },
      on: {
        close: function($event) {
          return _vm.onCloseModal()
        }
      },
      model: {
        value: _vm.modal.isOpen,
        callback: function($$v) {
          _vm.$set(_vm.modal, "isOpen", $$v)
        },
        expression: "modal.isOpen"
      }
    },
    [
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { cols: "12" } }, [
            _c("img", {
              staticClass: "img-fluid pb-3",
              attrs: {
                src: require("@/assets/images/anothers/referenciasPagos.png"),
                alt: "referencias pago"
              }
            })
          ]),
          _c(
            "b-col",
            { staticClass: "content-center", attrs: { cols: "12" } },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "danger" },
                  on: {
                    click: function($event) {
                      return _vm.onCloseModal()
                    }
                  }
                },
                [_vm._v("Cerrar")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }