var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          _c("p", { staticClass: "primary fs-24 fw-bold py-2" }, [
            _vm._v("Levantamiento de Prenda")
          ]),
          !_vm.showform
            ? [
                _c("p", { staticClass: "fw-bold" }, [
                  _vm._v(
                    "Solicita tu levantamiento de prenda SOLO si cumples los siguientes requisitos:"
                  )
                ]),
                _c("p", { staticClass: "p-0 m-0 text-justify" }, [
                  _c("span", { staticClass: "italic underline" }, [
                    _vm._v("Si tu motocicleta es Honda:")
                  ]),
                  _vm._v(
                    " La pignoración de la tarjeta de propiedad debe estar bajo el nombre de: Banco de Occidente, Giros & Finanzas, ADEINCO S.A. o Administración e Inversiones Comerciales S.A. "
                  )
                ]),
                _c("p", { staticClass: "p-0 m-0 text-justify" }, [
                  _c("span", { staticClass: "italic underline" }, [
                    _vm._v("Si tu moto es de otra marca:")
                  ]),
                  _vm._v(
                    " La pignoración debe estar validada solo por el nombre de ADEINCO S.A. o Administración e Inversiones Comerciales S.A. "
                  )
                ]),
                _c("p", { staticClass: "p-0 m-0 text-justify" }, [
                  _c("span", { staticClass: "fw-bold" }, [
                    _vm._v("Si cumple con lo anterior")
                  ]),
                  _vm._v(", consigna "),
                  _c("span", { staticClass: "fw-bold danger" }, [
                    _vm._v("$30.000")
                  ]),
                  _vm._v(
                    " en Bancolombia al convenio 14761 a nombre de ADEINCO PRENDA. registra el "
                  ),
                  _c("span", { staticClass: "fw-bold" }, [
                    _vm._v(
                      "nombre y la cédula de quién aparece en la tarjeta de propiedad."
                    )
                  ])
                ]),
                _c(
                  "p",
                  { staticClass: "p-0 mt-3 mb-3 text-justify danger fw-bold" },
                  [
                    _vm._v(
                      " RECUERDA: Tu levantamiento de prenda NO podrá ser entregado en VEREDAS o ZONAS RURALES registra una dirección de entrega ubicada en la CIUDAD PRINCIPAL más cercana "
                    )
                  ]
                ),
                _c(
                  "b-row",
                  { staticClass: "mt-3 mb-5" },
                  [
                    _c(
                      "b-col",
                      {
                        staticClass: "content-center",
                        attrs: { cols: "12", md: "9" }
                      },
                      [
                        _c(
                          "b-form-checkbox",
                          {
                            model: {
                              value: _vm.readRequisitos,
                              callback: function($$v) {
                                _vm.readRequisitos = $$v
                              },
                              expression: "readRequisitos"
                            }
                          },
                          [
                            _vm._v(
                              " He leído los requisitos de levantamiento de prenda "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      {
                        staticClass: "d-flex justify-content-end",
                        attrs: { cols: "12", md: "3" }
                      },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              variant: "danger",
                              disabled: !_vm.readRequisitos
                            },
                            on: { click: _vm.aceptarRequisitos }
                          },
                          [
                            _c("i", { staticClass: "las la-check-circle" }),
                            _vm._v(" Aceptar ")
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            : [
                _c(
                  "b-row",
                  [
                    _c("FormUsuario", {
                      ref: "formUsuario",
                      on: { updateForm: _vm.updateForm }
                    }),
                    _c(
                      "b-col",
                      { staticClass: "pt-2", attrs: { cols: "12" } },
                      [
                        _c("p", { staticClass: "fw-bold primary" }, [
                          _vm._v("Dirección envío levantamiento de prendas")
                        ]),
                        _c("p", { staticClass: "fs-14 italic fw-bold" }, [
                          _vm._v(
                            " El levantamiento de prenda se enviará físico por correo única vez a la dirección seleccionada. Si ocurre alguna novedad en el proceso de entrega, el cliente asume los costos de los envíos posteriores. "
                          )
                        ]),
                        _c(
                          "div",
                          { staticClass: "alert alert-info text-center" },
                          [
                            _vm._v(
                              " Te informamos que tu levantamiento de prenda "
                            ),
                            _c("span", { staticClass: "fw-bold" }, [
                              _vm._v("NO")
                            ]),
                            _vm._v(" podrá ser entregado en "),
                            _c("span", { staticClass: "fw-bold" }, [
                              _vm._v("VEREDAS O ZONAS RURALES")
                            ]),
                            _vm._v(
                              ", registra una dirección de entrega ubicada en la "
                            ),
                            _c("span", { staticClass: "fw-bold" }, [
                              _vm._v("CIUDAD PRINCIPAL")
                            ]),
                            _vm._v(" más cercana. ")
                          ]
                        )
                      ]
                    ),
                    _c("FormDireccion", {
                      ref: "formDireccion",
                      on: { updateForm: _vm.updateForm }
                    }),
                    _c(
                      "b-col",
                      { staticClass: "pt-2", attrs: { cols: "12" } },
                      [
                        _c("p", { staticClass: "fw-bold primary" }, [
                          _vm._v("Información del propietario")
                        ]),
                        _c("p", { staticClass: "fs-14 italic fw-bold" }, [
                          _vm._v(
                            " Recuerda que debes incluir el nombre de la persona que registra en la tarjeta de propiedad de la motocicleta. "
                          )
                        ])
                      ]
                    ),
                    _c("FormPropietario", {
                      ref: "formPropietario",
                      on: { updateForm: _vm.updateForm }
                    }),
                    _c("b-col", [
                      _c("p", { staticClass: "fw-bold italic" }, [
                        _vm._v("* Campos Obligatorios")
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { staticClass: "content-end-center pb-5" },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "danger" },
                            on: { click: _vm.sendForm }
                          },
                          [
                            _c("i", { staticClass: "las la-check-circle" }),
                            _vm._v(" Solicitar")
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("ModalLevantamientoPrenda", {
                  attrs: { modal: _vm.modal, data: _vm.formLP },
                  on: { useModal: _vm.useModal }
                }),
                _c("ModalValidarOtp", {
                  ref: "modalOtp",
                  attrs: {
                    tiempoReenvioCodigo: _vm.tiempoHabilitarReenvio,
                    digitosCelular: _vm.digitosCelular,
                    modal: _vm.modalOtp
                  },
                  on: {
                    reenviarCodigo: _vm.reenviarCodigo,
                    sendForm: _vm.validarCodigo,
                    useModal: _vm.useModalOtp
                  }
                })
              ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }