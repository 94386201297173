var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-col",
    { attrs: { cols: "12" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", lg: "6" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Nombre propietario *" } },
                [
                  _c("b-form-input", {
                    attrs: {
                      state: _vm.$v.formP.nombrePropietario.$dirty
                        ? !_vm.$v.formP.nombrePropietario.$error
                        : null
                    },
                    on: { keyup: _vm.upperCase },
                    model: {
                      value: _vm.$v.formP.nombrePropietario.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.formP.nombrePropietario, "$model", $$v)
                      },
                      expression: "$v.formP.nombrePropietario.$model"
                    }
                  }),
                  _c("b-form-invalid-feedback", [
                    _vm._v(
                      _vm._s(_vm.validarCampo(_vm.$v.formP.nombrePropietario))
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", lg: "6" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Cédula propietario *" } },
                [
                  _c("b-form-input", {
                    attrs: {
                      state: _vm.$v.formP.identificacionPropietario.$dirty
                        ? !_vm.$v.formP.identificacionPropietario.$error
                        : null
                    },
                    model: {
                      value: _vm.$v.formP.identificacionPropietario.$model,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.$v.formP.identificacionPropietario,
                          "$model",
                          $$v
                        )
                      },
                      expression: "$v.formP.identificacionPropietario.$model"
                    }
                  }),
                  _c("b-form-invalid-feedback", [
                    _vm._v(
                      _vm._s(
                        _vm.validarCampo(_vm.$v.formP.identificacionPropietario)
                      )
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", lg: "6" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Placa *" } },
                [
                  _c("b-form-input", {
                    attrs: {
                      state: _vm.$v.formP.placa.$dirty
                        ? !_vm.$v.formP.placa.$error
                        : null
                    },
                    on: { keyup: _vm.upperCase },
                    model: {
                      value: _vm.$v.formP.placa.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.formP.placa, "$model", $$v)
                      },
                      expression: "$v.formP.placa.$model"
                    }
                  }),
                  _c("b-form-invalid-feedback", [
                    _vm._v(_vm._s(_vm.validarCampo(_vm.$v.formP.placa)))
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("b-col", { attrs: { sm: "12", lg: "6" } }, [
            _c(
              "div",
              { attrs: { role: "group" } },
              [
                _c(
                  "label",
                  { staticClass: "primary", attrs: { for: "referenciaPago" } },
                  [
                    _vm._v(" Referencia de pago * "),
                    _c("i", {
                      staticClass: "la la-question-circle la-lg",
                      on: { click: _vm.useModal }
                    })
                  ]
                ),
                _c("b-form-input", {
                  attrs: {
                    id: "referenciaPago",
                    state: _vm.$v.formP.referenciaPago.$dirty
                      ? !_vm.$v.formP.referenciaPago.$error
                      : null
                  },
                  on: { keypress: _vm.onlyNumber },
                  model: {
                    value: _vm.$v.formP.referenciaPago.$model,
                    callback: function($$v) {
                      _vm.$set(_vm.$v.formP.referenciaPago, "$model", $$v)
                    },
                    expression: "$v.formP.referenciaPago.$model"
                  }
                }),
                _c("b-form-invalid-feedback", [
                  _vm._v(_vm._s(_vm.validarCampo(_vm.$v.formP.referenciaPago)))
                ])
              ],
              1
            )
          ])
        ],
        1
      ),
      _c("ModalReferenciasPago", {
        attrs: { modal: _vm.modal },
        on: { useModal: _vm.useModal }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }